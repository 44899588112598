import { Col, Popover, Row, notification, Button } from "antd";
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import mixpanel from "mixpanel-browser";

import { convertDateAndTime } from '../../utils/CommonUtils';
import { ALIKE_MODERATOR, DEV, MODERATOR, SA } from '../../constants/UserRolesConstant';
import {
    LIKE_COMMENT,
    MORE_ICON_COMMENT,
    FLAG,
    DELETE_COMMENT_DROPDOWN,
    FLAG_FILLED,
    LIKE_POST,
    LIKE_POST_LINED,
    IMG_MENTION,
} from "../../utils/ImageUtils";
import { addChildReaction, addReaction, deleteReaction } from '../../utils/forumFeedService';
import { CHILD_REACTION_TYPES, FEED_IMAGE_URL, GIF_TYPE, TIME_FILTERS } from '../../constants/GlobalConstant';
import _ from 'lodash';
import DeleteRestoreConfirmationModal from '../UI/DeleteRestoreConfirmationModal';
import ApiService from '../../utils/ApiService';
import { FeedFlagModal } from './FeedFlagModal';
import UserAvatar from './UserAvatar';
import PopoverAction from "../UI/PopoverAction";
import LinkifiedUsername from "../UI/LinkifiedUsername";

const SingleComment = inject("store")((props) => {
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
    const [toDelete, setToDelete] = useState(true);
    const [isShowFlagOptionModal, setIsShowFlagOptionModal] = useState(false);
    const [isLiked, setIsLiked] = useState(false);
    const [likeCount, setLikeCount] = useState(0);
    const [isFlagged, setIsFlagged] = useState(false);
    const [flagCount, setFlagCount] = useState(0);
    const {
        comment,
        isSelected,
        isAlertScreen,
        commentIndex,
        isDeleteOnly,
        isReadOnly,
        onDeletionComplete,
        isFeedDeleted,
        addUserMentionToComment,
        store: {
            AuthStore: { type, userId: loggedInUserId },
            ForumFeedStore: { selectedTimeFilter },
        }
    } = props;
    const commentText = comment?.data?.comment;
    const commentUsername = comment?.user?.data?.username || 'john_123';
    const commentAttachment = _.get(comment, 'data.attachments.0');
    const isGif = _.get(comment, 'data.attachments.0.type') === GIF_TYPE;
    const isFlaggedFilterActive = selectedTimeFilter === TIME_FILTERS.FLAGGED;
    const isCommentLoading = comment?.isLoading;
    const showMentionButton = !isAlertScreen && !!addUserMentionToComment;

    useEffect(() => {
        if (comment) {
            setIsLiked(comment?.own_children?.like?.length > 0);
            setLikeCount(comment?.children_counts?.like || 0);
            setIsFlagged(comment?.own_children?.flag?.length > 0);
            setFlagCount(comment?.children_counts?.flag || 0);
        } else {
            setIsLiked(false);
            setLikeCount(0);
            setIsFlagged(false);
            setFlagCount(0);
        }
    }, [comment]);

    const onClickReaction = async (reactionType, isReacted) => {
        if (!isReadOnly && !isDeleteOnly) {
            const {
                comments,
                setComments,
            } = props.store.ForumFeedStore;
            const commentsClone = _.cloneDeep(comments);

            let reaction, addReactionResponse;
    
            if (isReacted) {
                /** update store */
                const oldCount = _.get(commentsClone, `${[commentIndex]}.children_counts.${reactionType}`, 1);
                _.set(commentsClone, `${[commentIndex]}.children_counts.${reactionType}`, oldCount - 1);
                _.unset(commentsClone, `${[commentIndex]}.own_children.${reactionType}`);
                setComments([...commentsClone]);
    
                /** delete from getstream */
                const childReactionId = _.get(comment, `own_children.${reactionType}.0.id`);
                await deleteReaction(childReactionId);
    
                /** Add unlike reaction to update through subscriptions */
                reaction = `un${reactionType}`;
                addReactionResponse = await addChildReaction(reaction, comment?.id, { commentId: comment?.id, reactionId: childReactionId });
            } else {
                /** update store */
                const oldCount = _.get(commentsClone, `${[commentIndex]}.children_counts.${reactionType}`, 0);
                _.set(commentsClone, `${[commentIndex]}.children_counts.${reactionType}`, oldCount + 1);
                
                const ownChildren = _.get(commentsClone, `${[commentIndex]}.own_children.${reactionType}`, []);
                ownChildren.unshift({});
                _.set(commentsClone, `${[commentIndex]}.own_children.${reactionType}`, ownChildren);
                setComments([...commentsClone]);
                
                /** add child-reaction */
                reaction = reactionType;
                addReactionResponse = await addChildReaction(reaction, comment?.id, { commentId: comment?.id });
                ownChildren[0] = addReactionResponse;
                _.set(commentsClone, `${[commentIndex]}.own_children.${reactionType}`, ownChildren);
                setComments([...commentsClone]);
            }

            mixpanel.track(`Forum comment ${reaction}`, {
                from: "WEB",
                type: reaction,
                activity_id: addReactionResponse.activity_id,
                id: addReactionResponse.id,
                data: JSON.stringify(addReactionResponse),
                comment_id: comment?.id,
            });
        }
    }

    const debouncedOnClickReaction = useDebouncedCallback(
        (reactionType) => {
            const previousLikeState = comment?.own_children?.like?.length > 0;
            const previousFlagState = comment?.own_children?.flag?.length > 0;
            if (previousLikeState !== isLiked || previousFlagState !== isFlagged) {
                onClickReaction(reactionType, reactionType === CHILD_REACTION_TYPES.flag ? previousFlagState : previousLikeState);
            }
        },
        1000
    );

    const handleClickReaction = (reactionType) => {
        if (reactionType === CHILD_REACTION_TYPES.like) {
            setIsLiked(!isLiked);
            setLikeCount(isLiked ? likeCount - 1 : likeCount + 1);
        } else if (reactionType === CHILD_REACTION_TYPES.flag) {
            setIsFlagged(!isFlagged);
            setFlagCount(isFlagged ? flagCount - 1 : flagCount + 1);
        }
        debouncedOnClickReaction(reactionType);
    }

    const onDelete = () => {
        setIsConfirmationModalOpen(true);
        setToDelete(true);
    }

    const deleteComment = async (id, commentIndex) => {
        const { 
            comments,
            setComments,
            feeds,
            setFeeds,
            currentFeedId,
            setDeleteCommentLoading,
        } = props.store.ForumFeedStore;
        try {
            setDeleteCommentLoading(commentIndex, true);
            setIsConfirmationModalOpen(false);
            
            const path = `feeds?commentId=${id}`;
            await ApiService.deleteRequest(path);
            await addReaction(`uncomment`, currentFeedId, { reactionId: id }, `${props.store.AuthStore.userId}`);

            mixpanel.track("Forum comment deleted", {
                from: "WEB",
                activity_id: currentFeedId,
                comment_id: id,
            });

            if(onDeletionComplete) {
                onDeletionComplete();
            }
            // update comments in store
            const commentsClone = _.cloneDeep(comments);
            commentsClone.splice(commentIndex, 1);
            setComments([...commentsClone]);

            /** update reaction count */
            const feedsClone = _.cloneDeep(feeds);
            const feedIndex = feedsClone.findIndex(ele => ele?.id === currentFeedId)
            const oldCount = _.get(feedsClone, `${[feedIndex]}.reaction_counts.comment`, 0);
            _.set(feedsClone, `${[feedIndex]}.reaction_counts.comment`, oldCount - 1);
            
            /** update store data */
            const ownComments = _.get(feedsClone, `${[feedIndex]}.own_reactions.comment`, []);
            _.set(feedsClone, `${[feedIndex]}.own_reactions.comment`, ownComments.filter(ele => ele?.id !== id));
            setFeeds([...feedsClone]);

            // reset
            notification.success({
                message: 'Success',
                description: 'Your comment has been successfully deleted',
                placement: 'bottomRight',
            });
        } catch(error) {
            notification.error({
                message: 'Error',
                description: error?.message,
                placement: 'bottomRight',
            });
            throw error;
        }
    }

    const flagClickHandler = async (isFlagged) => {
        if (!isReadOnly && !isDeleteOnly) {
            const { type: userType } = props.store.AuthStore;
            if ([MODERATOR, SA, DEV].includes(userType) && !isFlagged) {
                setIsShowFlagOptionModal(true);
            } else {
                handleClickReaction(CHILD_REACTION_TYPES.flag)
            }
        }
    }

    const onCloseFlagReactionModal = () => {
        setIsShowFlagOptionModal(false);
    };

    const onSubmitFlagReaction = async (data) => {
        handleClickReaction(CHILD_REACTION_TYPES.flag)

        const { currentFeedId } = props.store.ForumFeedStore;
        const { typeOfNeed, isAnonimityCompromised, description } = data;
        const path = 'forum-flags';
        const payload = {
            activityId: currentFeedId,
            commentId: comment?.id,
            typeOfNeed: `${typeOfNeed}`,
            isAnonimityCompromised,
            description,
        };
        await ApiService.postRequest(path, payload);

        notification.success({
            message: 'Success',
            description: 'Flagged successful',
            placement: 'bottomRight',
        });

        setIsShowFlagOptionModal(false);
    };

    const viewReaction = () => {
        return (
            <div
                style={{ backgroundColor: "white", width: "100%", marginRight: 5 }}
            >
                {
                    !isDeleteOnly &&
                    <React.Fragment>
                        <p className='feed-comment-action-dropdown' onClick={() => handleClickReaction(CHILD_REACTION_TYPES.like)}>
                            <PopoverAction
                              actionName={isLiked ? 'Liked' : 'Like'}
                              icon={isLiked ? LIKE_POST : LIKE_POST_LINED}
                            />
                        </p>
                        <p
                          className='feed-comment-action-dropdown'
                          onClick={() => flagClickHandler(isFlagged)}
                          hidden={isFlaggedFilterActive && !isFlagged}
                        >
                            <PopoverAction
                                actionName={isFlagged ? 'Unflag' : 'Flag'}
                                icon={isFlagged ? FLAG_FILLED : FLAG}
                            />
                        </p>
                    </React.Fragment>
                }
                {
                    (+loggedInUserId === +comment?.user?.data?.id || ALIKE_MODERATOR.includes(type)) &&
                    <>
                        <p className='feed-comment-action-dropdown' 
                            onClick={(e) => { !isCommentLoading && onDelete() }}
                        >
                            <PopoverAction 
                                actionName="Delete"
                                icon={DELETE_COMMENT_DROPDOWN}
                                isDisabled={isCommentLoading}
                            />
                        </p>
                    </>
                }
            </div >
        );
    };

    return (<>
            <div 
                style={{ 
                    opacity: isAlertScreen && !isSelected && '30%', 
                    padding: '10px 30px',
                }}
            >
                {/** comment user start */}
                {
                    commentUsername && 
                    <Row key={commentIndex} style={{ margin: '10px 0px 28px' }}>
                        <Col span={commentText?.length > 35 ? 13 : 10}>
                            <Row>
                                <Col span={12}>
                                    <UserAvatar
                                        userData={comment?.user?.data}
                                        showBadge={true}
                                    />
                                </Col>
                                <Col span={12} id='feed-comment-label'>
                                    {
                                        isFeedDeleted
                                            ? <p>Post Deleted</p>
                                            : (comment?.data?.deleted_at && comment?.data?.deleted_at !== null) &&
                                                <p>Comment Deleted</p>
                                    }
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                }
                {/** comment user ends */}
                {/* start comment */}
                {
                    <Row>
                        <Col span={commentText?.length > 35 ? 13 : 10}>
                            {/** comment data starts */}
                            {
                                (commentText || commentAttachment) &&
                                <div style={{
                                    border: "solid lightBlue",
                                    borderRadius: 20, 
                                    borderTopLeftRadius: 0, 
                                    marginTop: -17
                                }}>
                                    <Row>
                                        <Col span={24}
                                            style={{
                                                padding: 10,
                                                fontSize: 16,
                                                color: 'black',
                                                border: 'lightBlue',
                                                overflowWrap: 'break-word',
                                                whiteSpace: 'pre-wrap',
                                            }}>
                                            {commentText &&
                                                <LinkifiedUsername text={commentText}/>
                                            }
                                            {commentAttachment && 
                                                <div>
                                                    <img
                                                        src={`${(!isGif ? `${FEED_IMAGE_URL}` : '')}${commentAttachment?.url}`}
                                                        alt={`${commentAttachment?.url}`}
                                                        style={{
                                                            width: '100%',
                                                            height: '300px',
                                                            objectFit: 'contain',
                                                            alignContent: 'center',
                                                        }}
                                                    />
                                                </div>
                                            }
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col style={{ display: 'flex', justifyContent: 'right', marginRight: '5%' }}>
                                            <span style={{ fontSize: 12 }}>{convertDateAndTime(comment?.created_at)}</span>
                                        </Col>
                                    </Row>
                                </div>
                            }   
                            {/** comment data ends */}

                            {/* comment reaction starts */}
                            <Row>
                                <Col span={23}>
                                    <div className='message-reactions-count' style={{ marginTop: -15, marginLeft: 10 }} >
                                        {
                                            likeCount > 0 &&
                                            <div className='badge'
                                                style={true ? { display: 'inline-block', marginTop: -25 } : { display: 'none' }}
                                            >
                                                <div className="d-flex align-items-center justify-content-between" style={{ height: 20 }}>
                                                    <span>
                                                        <img src={LIKE_COMMENT}
                                                            onClick={e => {}}
                                                            alt="likes"
                                                            className="message-reactions" 
                                                        />
                                                    </span>
                                                    <span>{likeCount}</span>
                                                </div>
                                            </div>
                                        }
                                        {
                                            flagCount > 0 &&
                                            <div 
                                                className='badge'
                                                style={true ? { display: 'inline-block', marginTop: -25 } : { display: 'none' }} 
                                            >
                                                <div className="d-flex align-items-center justify-content-between" style={{ height: 20 }}>
                                                    <span>
                                                        <img 
                                                            src={isReadOnly || isDeleteOnly || isFlagged || isFlaggedFilterActive ? FLAG_FILLED : FLAG}
                                                            alt="likes" 
                                                            className="message-reactions" 
                                                        />
                                                    </span>
                                                    <span>{flagCount}</span>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </Col>
                                {(!isReadOnly && !comment?.data?.deleted_at && !isFeedDeleted) && <Col span={1} style={{ marginTop: -15 }}>
                                    <Popover
                                        content={viewReaction()}
                                        trigger="hover"
                                    >
                                        <img
                                            src={MORE_ICON_COMMENT}
                                            alt="more"
                                            style={{ width: 25, marginTop: -10, cursor: 'pointer' }}
                                        />
                                    </Popover>
                                </Col>
                                }
                            </Row>
                            {/* comment reaction end */}
                        </Col>
                        {showMentionButton && <Col span={1} style={{
                            position: "absolute",
                            bottom: "50%",
                            left: commentText?.length > 35 ? "54.17%" : "41.67%",
                        }}>
                            <Button
                              onClick={() => {addUserMentionToComment(commentUsername)}}
                              shape="circle"
                              size="small"
                              className="chat-user-mention-button"
                            >
                                <img
                                  style={{
                                      height: '20px',
                                      width: '20px'
                                  }}
                                  src={IMG_MENTION} className="send-form" alt="Form"
                                />
                            </Button>
                        </Col>}
                    </Row>
                }
            </div>
            {/* comment end */}
            {/** delete confirmation modal */}
            <DeleteRestoreConfirmationModal
                isConfirmationModalOpen={isConfirmationModalOpen}
                handleOk={() => deleteComment(comment?.id, commentIndex)}
                handleCancel={() => setIsConfirmationModalOpen(false)}
                toDelete={toDelete}
                type={'Comment'}
            />
            {/** delete confirmation modal ends */}
            {/** Flag options modal starts */}
            {isShowFlagOptionModal &&
                <FeedFlagModal
                    onOk={onSubmitFlagReaction}
                    visible={isShowFlagOptionModal}
                    flagReactionId={7}
                    messageObj={{}}
                    onClose={onCloseFlagReactionModal}
                />
            }
            {/** Flag options modal ends */}
    </>
    )
});

const ObservedComponent = observer(SingleComment);

// Wrap the observed component with React.memo
const MemoizedComponent = React.memo(ObservedComponent);

// Inject the store into the memoized component
export default inject("store")(MemoizedComponent);

